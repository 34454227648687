// success
export const SIGN_UP_USER = " SIGN_UP_USER";
export const SIGN_IN_USER = "SIGN_IN_USER";
// load user
export const LOAD_USER = "LOAD_USER";
// fail
export const FAIL_USER = "FAIL_USER";
//import current user
export const CURRENT_USER = "CURRENT_USER";
//logout
export const LOGOUT_USER = "LOGOUT_USER";
//clear errors
export const CLEAR_ERRORS = " CLEAR_ERRORS";
