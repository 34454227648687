const Errors = () => {
  return (
    <div className="error-page">
      <img
        width="30%"
        height="25%"
        src="assets/Error.jpg"
        alt="error"
        style={{ margin: "2% 35%" }}
      />
    </div>
  );
};

export default Errors;
