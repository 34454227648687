/*eslint-disable*/
import React from "react";

import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <span> &copy; Copyright {1900 + new Date().getYear()} Lost & Found in Tunisia. All rights reserved.
      </span>
    </footer>
  );
}
